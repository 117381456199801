import BaseService from './BaseService';

const ApiService = {
  async fetchData({ path, method, data }) {
    try {
      return await BaseService({ url: path, method, data });
    } catch (error) {
      throw error;
    }
  },
};

export default ApiService;
