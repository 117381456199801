const appConfig = {
  apiPrefix: process.env.NODE_ENV === 'test'? 'http://test/' : process.env.REACT_APP_API_PREFIX,
  authenticatedEntryPath: '/home',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale: 'en',
  enableMock: false,
};

export default appConfig;
