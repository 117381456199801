import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useQuery from './useQuery';

import appConfig from 'configs/app.config';

import { REDIRECT_URL_KEY } from 'constants/app.constant';

import {
  onSignInSuccess,
  onSignOutSuccess,
  setRefreshToken
} from 'store/auth/sessionSlice';
import { initialState, setUser } from 'store/auth/userSlice';

import { apiSignIn, apiSignUp } from 'services/AuthService';

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
        const { accessToken, refreshToken } = resp.data;
        dispatch(onSignInSuccess(accessToken));
        dispatch(setRefreshToken(refreshToken));
        if (resp.data.user) {
          dispatch(setUser(resp.data.user));
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: 'success',
          message: '',
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        if (resp.data.user) {
          dispatch(setUser(resp.data.user));
        }
        return {
          status: 'success',
          message: '',
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
    signedIn,
  };
}

export default useAuth;
