import { combineReducers } from 'redux';

import auth from './auth';
import base from './base';
import locale from './locale/localeSlice';
import theme from './theme/themeSlice';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    locale,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
