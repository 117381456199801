import TabContent from './TabContent';
import TabList from './TabList';
import TabNav from './TabNav';
import Tabs from './Tabs';

Tabs.TabList = TabList;
Tabs.TabNav = TabNav;
Tabs.TabContent = TabContent;

export default Tabs;
