import axios from 'axios';

import store from '../store';
import {
  onSignOutSuccess,
  setRefreshToken,
  setToken
} from '../store/auth/sessionSlice';

import appConfig from 'configs/app.config';

import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from 'constants/api.constant';
import { apiRefreshToken } from './AuthService';

const unauthorizedCode = [401];

const BaseService = axios.create({
  timeout: 60000,
  baseURL: appConfig.apiPrefix,
  headers: {
    'Content-Type': 'application/json',
  },
});

BaseService.interceptors.request.use(
  (config) => {
    const persistData = store.getState();

    const accessToken = persistData?.auth?.session?.token;

    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

BaseService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config: originalRequest } = error;

    if (response && unauthorizedCode.includes(response.status)) {
      if (originalRequest.url.includes('refresh')) {
        store.dispatch(onSignOutSuccess());
      } else {
        const persistData = store.getState();
        const refreshToken = persistData?.auth?.session?.refreshToken;
        const accessToken = persistData?.auth?.session?.token;
        const userEmail = persistData?.auth?.user?.email;
        try{
          const newLogin = await apiRefreshToken({
            refreshToken,
            token: accessToken,
            email: userEmail,
          });
          store.dispatch(setRefreshToken(newLogin.data.refreshToken));
          store.dispatch(setToken(newLogin.data.accessToken));
          return BaseService(originalRequest);
        } catch (e) {
          store.dispatch(onSignOutSuccess());
        }
      }
    }
    if (response && response.status === 403) {
      window.location.href = '/profile?payment=true';
    }

    return Promise.reject(error);
  },
);

export default BaseService;
